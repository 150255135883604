import { User } from './user';
import { CustomizedOrder } from './customized-order';

export class Proposal {

    id: string;
    customizedOrder: any;
    price: number;
    creation_date: any;
    proposal_state: any;
    proposer: any;
    final_price: number;
    description: string;
    orderPublisher: any;

    constructor(data: any = {
        id: null,
        customizedOrder: null,
        price: null,
        creation_date: null,
        proposal_state: 'in progress',
        proposer: null,
        final_price: null,
        description: null,
        orderPublisher: null
    }) {
        this.id = data.id;
        this.customizedOrder = data.customizedOrder;
        this.price = data.price;
        this.creation_date = data.creation_date;
        this.proposal_state = data.proposal_state;
        this.proposer = data.proposer;
        this.final_price = data.final_price;
        this.description = data.description;
        this.orderPublisher = data.orderPublisher;

    }


}

