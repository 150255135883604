import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';

@Injectable({
  providedIn: 'root'
})
export class OrderService {

  constructor(private afs: AngularFirestore) { }

  createNewOrder(orderData) {
    return this.afs.collection('order').add({...orderData});
  }

  getOrderByAdId (adId) {
    const ordersRef = this.afs.collection('order', ref => ref.where('menu.ad.id', '==', adId ));
    return ordersRef.valueChanges();
  }


}
