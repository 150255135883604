import { User } from './user';
import { CartItem } from './cart-item';
import { Ad } from './ad';

export class Order {

    public userId: string;
    public price: string;
    public date: any;
    public id: number;
    public menu: any;
    public quantity: number;
    public devise = 'EURO';
    public orderType = 'order';
    public state = 'new';

    constructor() {

    }

}
