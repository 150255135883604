import { Dish } from './dish';
import { User } from './user';

export class CustomizedOrder {

    id: string;
    title: string;
    ready_at: any;
    orders_nbr: number;
    speciality = [];
    creation_date: any;
    address: any;
    position: any;
    price: number;
    publisher: any;
    state = 'new';
    menuImg: any;
    description: any;
    distance: string;
    choosenProposal: string;

    constructor(data: any = {
        id: null,
        title: null,
        ready_at: null,
        publisher: null,
        creation_date: null,
        speciality: [],
        orders_nbr: null,
        price: null,
        address: null,
        position: null,
        state: null,
        menuImg: null,
        description: null,
        choosenProposal: null,
        queryMetadata: {
            distance: null
        },
    }) {
        this.id = data.id;
        this.title = data.title;
        this.ready_at = data.ready_at;
        this.creation_date = data.creation_date;
        this.price = data.price;
        this.speciality = data.speciality;
        this.state = data.state;
        this.menuImg = data.menuImg;
        this.description = data.description;
        this.orders_nbr = data.orders_nbr;
        this.distance = data.queryMetadata ? data.queryMetadata.distance : null;
        this.publisher = data.publisher;
        this.choosenProposal = data.choosenProposal ? data.choosenProposal : null;
        this.address = data.address;
        this.position = data.position;

    }


}

